var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-row',{attrs:{"if":"articles.length >= 1"}},[_c('div',{staticClass:"col-md-12 text-right"},[(_vm.$auth.check('producers.export'))?_c('button',{staticClass:"btn btn-sm btn-warning",on:{"click":_vm.exportLocafox}},[_c('i',{staticClass:"fas fa-file-export"}),_vm._v(" Locafox Export")]):_vm._e()])]),(_vm.articles.length >= 1)?_c('b-row',{staticClass:"mt-2"},[_c('b-col',[_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.articles,"search-options":{ 
                    enabled: true
                },"pagination-options":{
                    enabled: true,
                    mode: 'pages',
                    perPage: 20,
                    perPageDropdownEnabled: true,
                    nextLabel: 'Nächste',
                    prevLabel: 'Vorherige',
                    rowsPerPageLabel: 'Zeilen pro Seite',
                    ofLabel: 'von',
                    pageLabel: 'Seite',
                    allLabel: 'Alle',
                },"styleClass":"vgt-table condensed striped"},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'action')?_c('span',[(_vm.$auth.check('articles.show'))?_c('router-link',{staticClass:"btn btn-success btn-sm mr-1",attrs:{"to":{name: 'articles.show', params: {id: props.row.id}}}},[_c('i',{staticClass:"fas fa-eye"})]):_vm._e()],1):_vm._e(),(props.column.field == 'active')?_c('span',[(props.row.active == 1)?_c('span',[_c('i',{staticClass:"fas fa-check ml-2"})]):_vm._e(),(props.row.active == 0)?_c('span',[_c('i',{staticClass:"fas fa-times ml-2"})]):_vm._e()]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}],null,false,3991145379)})],1)],1):_vm._e(),(_vm.articles.length == 0)?_c('b-row',[_c('b-col',[_c('h4',[_vm._v("Dieser Hersteller hat keine Artikel")])])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }